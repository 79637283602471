<template>
    <v-container fluid>
        <v-alert dense outlined type="error" v-if="Object.keys(validation_errors).length > 0">
            <ul>
                <li v-for="(errors, field) in validation_errors" :key="field">
                    {{ field }}
                    <ul>
                        <li v-for="(error, sl) in errors" :key="sl">
                            {{ error }}
                        </li>
                    </ul>
                </li>
            </ul>
        </v-alert>
        <v-form @submit.prevent="saveEmployeeInfo" ref="employeeInfoForm" class="custom-form">
            <v-row dense>
                <v-col cols="12" md="3">
                    <v-card dark id="employee_info" color="primary" :elevation="1">
                        <v-card-text class="pa-0 text-center">
                            <v-row>
                                <v-col cols="12">
                                    <div class="position-relative">
                                        <v-dialog width="400">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn icon x-large v-bind="attrs" v-on="on">
                                                    <v-avatar color="primary darken-1" size="120">
                                                        <v-img v-if="employeeImagePreview"
                                                            :src="employeeImagePreview"></v-img>
                                                        <v-icon v-else dark>mdi-account-circle</v-icon>
                                                    </v-avatar>
                                                </v-btn>
                                            </template>

                                            <v-card>
                                                <v-card-title class="grey lighten-2 text-center">
                                                    <v-img v-if="employeeImagePreview"
                                                        :src="employeeImagePreview"></v-img>
                                                    <v-img v-else :src="$store.state.host + 'no_image.svg'"></v-img>
                                                </v-card-title>
                                            </v-card>
                                        </v-dialog>
                                        <v-file-input class="employee-image-input" hide-input truncate-length="15"
                                            accept=".png, .jpg" v-model="employeeImage"></v-file-input>
                                    </div>
                                </v-col>
                                <v-col cols="12" class="py-0">
                                    <h1 class="subtitle-2">{{ employee.name ? employee.name : "Employee Name" }}</h1>
                                    <p class="caption mb-0">
                                        {{ selectedDesignation ? selectedDesignation.name : "Designation" }}{{
                                            selectedDepartment ? `, ${selectedDepartment.name}` : ", Department" }}
                                    </p>
                                    <p class="caption mb-0">
                                        {{ employee.phone ? employee.phone : "Phone Number" }}
                                    </p>
                                </v-col>
                            </v-row>
                        </v-card-text>

                        <v-card-actions class="text-center">
                            <v-row dense>
                                <v-col cols="6" class="pb-0">
                                    <div class="position-relative">
                                        <v-dialog width="400">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn icon x-large v-bind="attrs" v-on="on">
                                                    <v-avatar color="primary darken-1" size="80">
                                                        <v-img v-if="signatureImagePreview"
                                                            :src="signatureImagePreview"></v-img>
                                                        <v-icon v-else dark>mdi-account-circle</v-icon>
                                                    </v-avatar>
                                                </v-btn>
                                            </template>

                                            <v-card>
                                                <v-card-title class="grey lighten-2 text-center">
                                                    <v-img v-if="signatureImagePreview"
                                                        :src="signatureImagePreview"></v-img>
                                                    <v-img v-else :src="$store.state.host + 'no_image.svg'"></v-img>
                                                </v-card-title>
                                            </v-card>
                                        </v-dialog>
                                        <v-file-input class="image-input" hide-input truncate-length="15"
                                            accept=".png, .jpg" v-model="signatureImage"></v-file-input>
                                    </div>
                                    <p class="caption mt-2 mb-0">Signature</p>
                                </v-col>
                                <v-col cols="6" class="pb-0">
                                    <div class="position-relative">
                                        <v-dialog width="400">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn icon x-large v-bind="attrs" v-on="on">
                                                    <v-avatar color="primary darken-1" size="80">
                                                        <v-img v-if="nomineeImagePreview"
                                                            :src="nomineeImagePreview"></v-img>
                                                        <v-icon v-else dark>mdi-account-circle</v-icon>
                                                    </v-avatar>
                                                </v-btn>
                                            </template>

                                            <v-card>
                                                <v-card-title class="grey lighten-2 text-center">
                                                    <v-img v-if="nomineeImagePreview"
                                                        :src="nomineeImagePreview"></v-img>
                                                    <v-img v-else :src="$store.state.host + 'no_image.svg'"></v-img>
                                                </v-card-title>
                                            </v-card>
                                        </v-dialog>
                                        <v-file-input class="image-input" hide-input truncate-length="15"
                                            accept=".png, .jpg" v-model="nomineeImage"></v-file-input>
                                    </div>
                                    <p class="caption mt-2 mb-0">Nominee</p>
                                </v-col>
                            </v-row>
                        </v-card-actions>
                    </v-card>
                </v-col>

                <v-col cols="12" md="9">
                    <v-card height="100%" :elevation="1">
                        <v-toolbar color="white" :elevation="1" height="30px">
                            <v-toolbar-title class="subtitle-2">Personal Information</v-toolbar-title>
                        </v-toolbar>
                        <v-card-text class="py-0">
                            <v-row>
                                <v-col cols="12" md="6">
                                    <v-row>
                                        <v-col cols="4" class="py-1 pr-1 caption">Full Name</v-col>
                                        <v-col cols="8" class="py-0">
                                            <v-text-field dense outlined hide-details v-model.trim="employee.name"
                                                :rules="[(v) => !!v]"
                                                :error-messages="validation_errors.name"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="4" class="py-1 pr-1 caption">Father's Name</v-col>
                                        <v-col cols="8" class="py-0">
                                            <v-text-field dense outlined hide-details
                                                v-model.trim="employee.father_name" :rules="[(v) => !!v]"
                                                :error-messages="validation_errors.father_name"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="4" class="py-1 pr-1 caption">Mother's Name</v-col>
                                        <v-col cols="8" class="py-0">
                                            <v-text-field dense outlined hide-details
                                                v-model.trim="employee.mother_name"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="4" class="py-1 pr-1 caption">Present Address</v-col>
                                        <v-col cols="8" class="py-0 pb-1">
                                            <v-textarea outlined hide-details :rows="2"
                                                v-model.trim="employee.present_address"></v-textarea>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="4" class="py-1 pr-1 caption">Permanent Address</v-col>
                                        <v-col cols="8" class="py-0 pb-1">
                                            <v-textarea outlined hide-details :rows="2"
                                                v-model.trim="employee.permanent_address"></v-textarea>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="4" class="py-1 pr-1 caption">Area</v-col>
                                        <v-col cols="8" class="py-0">
                                            <v-combobox dense outlined hide-details item-text="name" item-value="id"
                                                @focus="$store.dispatch('area/getAreas')"
                                                :loading="$store.getters['area/loadingAreas']"
                                                :items="$store.getters['area/areas']" :rules="[(v) => !!v]"
                                                v-model="selectedArea" :error-messages="validation_errors.area_id"
                                                append-outer-icon="mdi-plus"
                                                @click:append-outer="$router.push('/area')"></v-combobox>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="4" class="py-1 pr-1 caption">Country</v-col>
                                        <v-col cols="8" class="py-0">
                                            <v-combobox dense outlined hide-details item-text="name" item-value="id"
                                                @focus="$store.dispatch('country/getCountries')"
                                                :loading="$store.getters['country/loadingCountries']"
                                                :items="$store.getters['country/countries']" :rules="[(v) => !!v]"
                                                v-model="selectedCountry" append-outer-icon="mdi-plus"
                                                @click:append-outer="$router.push('/country')"></v-combobox>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="4" class="py-1 pr-1 caption">Phone</v-col>
                                        <v-col cols="8" class="py-0">
                                            <v-text-field dense outlined hide-details :rules="[(v) => !!v]"
                                                v-model.trim="employee.phone"
                                                :error-messages="validation_errors.phone"></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-row>
                                        <v-col cols="4" class="py-1 pr-1 caption">Email</v-col>
                                        <v-col cols="8" class="py-0">
                                            <v-text-field dense outlined hide-details
                                                v-model.trim="employee.email"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="4" class="py-1 pr-1 caption">Gender</v-col>
                                        <v-col cols="8" class="py-0">
                                            <v-select dense outlined hide-details :items="['Male', 'Female', 'Others']"
                                                :rules="[(v) => !!v]" v-model="employee.gender"></v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="4" class="py-1 pr-1 caption">Date of Birth</v-col>
                                        <v-col cols="8" class="py-0">
                                            <v-menu max-width="290" v-model="birth_date_menu"
                                                :close-on-content-click="false">
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field dense readonly outlined hide-details v-on="on"
                                                        v-model="employee.birth_date"></v-text-field>
                                                </template>
                                                <v-date-picker no-title v-model="employee.birth_date"
                                                    @input="birth_date_menu = false"></v-date-picker>
                                            </v-menu>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="4" class="py-1 pr-1 caption">Blood Group</v-col>
                                        <v-col cols="8" class="py-0">
                                            <v-select dense outlined hide-details
                                                :items="['A+', 'A-', 'B+', 'B-', 'O+', 'O-', 'AB+', 'AB-']"
                                                v-model="employee.blood_group"></v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="4" class="py-1 pr-1 caption">Marital Status</v-col>
                                        <v-col cols="8" class="py-0">
                                            <v-select dense outlined hide-details
                                                :items="['Single', 'Married', 'Divorced', 'Widowed']"
                                                v-model="employee.marital_status"></v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="employee.marital_status == 'Married'">
                                        <v-col cols="4" class="py-1 pr-1 caption">Spouse Name</v-col>
                                        <v-col cols="8" class="py-0">
                                            <v-text-field dense outlined hide-details
                                                v-model.trim="employee.spouse_name"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="4" class="py-1 pr-1 caption">Religion</v-col>
                                        <v-col cols="8" class="py-0">
                                            <v-select dense outlined hide-details
                                                :items="['Islam', 'Hinduism', 'Buddhism', 'Christianity', 'Other']"
                                                :rules="[(v) => !!v]" v-model="employee.religion"></v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="4" class="py-1 pr-1 caption">Nationality</v-col>
                                        <v-col cols="8" class="py-0">
                                            <v-text-field dense outlined hide-details
                                                v-model.trim="employee.nationality"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="4" class="py-1 pr-1 caption">NID</v-col>
                                        <v-col cols="8" class="py-0">
                                            <v-text-field dense outlined hide-details
                                                v-model.trim="employee.nid"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="4" class="py-1 pr-1 caption">Details</v-col>
                                        <v-col cols="8" class="py-0">
                                            <v-textarea outlined hide-details :rows="2"
                                                v-model.trim="employee.details"></v-textarea>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>

            <v-row dense>
                <v-col cols="12" md="8">
                    <v-card height="100%" :elevation="1">
                        <v-toolbar color="white" :elevation="1" height="30px">
                            <v-toolbar-title class="subtitle-2">Employment Information</v-toolbar-title>
                        </v-toolbar>
                        <v-card-text class="py-0">
                            <v-row>
                                <v-col cols="12" md="6">
                                    <v-row>
                                        <v-col cols="4" class="py-1 pr-1 caption">Employee ID</v-col>
                                        <v-col cols="8" class="py-0">
                                            <v-text-field dense outlined hide-details v-model="employee.emp_id" readonly
                                                :rules="[(v) => !!v]"
                                                :error-messages="validation_errors.emp_id"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="4" class="py-1 pr-1 caption">Biometric ID</v-col>
                                        <v-col cols="8" class="py-0">
                                            <v-text-field dense outlined hide-details
                                                v-model.trim="employee.biometric_id" :rules="[(v) => !!v]"
                                                :error-messages="validation_errors.biometric_id"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="4" class="py-1 pr-1 caption">Department</v-col>
                                        <v-col cols="8" class="py-0">
                                            <v-combobox dense outlined hide-details item-text="name" item-value="id"
                                                @focus="$store.dispatch('department/getDepartments')"
                                                :items="$store.getters['department/departments']"
                                                :loading="$store.getters['department/loadingDepartments']"
                                                :rules="[(v) => !!v]" v-model="selectedDepartment"
                                                :error-messages="validation_errors.department_id"
                                                append-outer-icon="mdi-plus"
                                                @click:append-outer="$router.push('/department')"></v-combobox>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="4" class="py-1 pr-1 caption">Designation</v-col>
                                        <v-col cols="8" class="py-0">
                                            <v-combobox dense outlined hide-details item-text="name" item-value="id"
                                                @focus="$store.dispatch('designation/getDesignations')"
                                                :items="$store.getters['designation/designations']"
                                                :loading="$store.getters['designation/loadingDesignations']"
                                                :rules="[(v) => !!v]" v-model="selectedDesignation"
                                                :error-messages="validation_errors.designation_id"
                                                append-outer-icon="mdi-plus"
                                                @click:append-outer="$router.push('/designation')"></v-combobox>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="4" class="py-1 pr-1 caption">Employment Type</v-col>
                                        <v-col cols="8" class="py-0">
                                            <v-select dense outlined hide-details
                                                :items="['Temporary', 'Permanent', 'Hourly', 'Contractual']"
                                                :rules="[(v) => !!v]" v-model="employee.employment_type"></v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="4" class="py-1 pr-1 caption">Shift</v-col>
                                        <v-col cols="8" class="py-0">
                                            <v-combobox dense outlined hide-details item-text="name" item-value="id"
                                                @focus="$store.dispatch('shift/getShifts')"
                                                :items="$store.getters['shift/shifts']"
                                                :loading="$store.getters['shift/loadingShifts']" :rules="[(v) => !!v]"
                                                v-model="selectedShift" :error-messages="validation_errors.shift_id"
                                                append-outer-icon="mdi-plus"
                                                @click:append-outer="$router.push('/shift')"></v-combobox>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="4" class="py-1 pr-1 caption">Joining Date</v-col>
                                        <v-col cols="8" class="py-0">
                                            <v-menu max-width="290" v-model="joining_date_menu"
                                                :close-on-content-click="false">
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field dense readonly outlined hide-details v-on="on"
                                                        :rules="[(v) => !!v]"
                                                        v-model="employee.joining_date"></v-text-field>
                                                </template>
                                                <v-date-picker no-title v-model="employee.joining_date"
                                                    @input="joining_date_menu = false"></v-date-picker>
                                            </v-menu>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="4" class="py-1 pr-1 caption">Reporting Boss</v-col>
                                        <v-col cols="8" class="py-0">
                                            <v-combobox dense outlined hide-details item-text="display_name"
                                                item-value="id"
                                                @focus="$store.dispatch('employee/getEmployees', { apiParams: { status: 'a' }, options: {} })"
                                                :items="$store.getters['employee/employees']"
                                                :loading="$store.getters['employee/loadingEmployees']"
                                                v-model="selectedReportingBoss"></v-combobox>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="4" class="py-1 pr-1 caption">Confirm Date</v-col>
                                        <v-col cols="8" class="py-0">
                                            <v-menu max-width="290" v-model="confirmation_date_menu"
                                                :close-on-content-click="false">
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field dense readonly outlined hide-details v-on="on"
                                                        v-model="employee.confirmation_date"></v-text-field>
                                                </template>
                                                <v-date-picker no-title v-model="employee.confirmation_date"
                                                    @input="confirmation_date_menu = false"></v-date-picker>
                                            </v-menu>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-row>
                                        <v-col cols="4" class="py-1 pr-1 caption">OT Applicable</v-col>
                                        <v-col cols="8" class="py-0">
                                            <v-select dense outlined hide-details :items="[
                                                {
                                                    text: 'Yes',
                                                    value: true,
                                                },
                                                {
                                                    text: 'No',
                                                    value: false,
                                                },
                                            ]" v-model="employee.ot_allowed"></v-select>
                                        </v-col>
                                    </v-row>

                                    <v-row v-if="employee.ot_allowed">
                                        <v-col cols="4" class="py-1 pr-1 caption">OT Amount</v-col>
                                        <v-col cols="8" class="py-0">
                                            <v-text-field dense outlined hide-details
                                                v-model.number="employee.ot_rate"></v-text-field>
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col cols="4" class="py-1 pr-1 caption">Allow Absence</v-col>
                                        <v-col cols="8" class="py-0">
                                            <v-select dense outlined hide-details :items="[
                                                {
                                                    text: 'Yes',
                                                    value: true,
                                                },
                                                {
                                                    text: 'No',
                                                    value: false,
                                                },
                                            ]" v-model="employee.absence_allowed"></v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="4" class="py-1 pr-1 caption">Allow Late</v-col>
                                        <v-col cols="8" class="py-0">
                                            <v-select dense outlined hide-details :items="[
                                                {
                                                    text: 'Yes',
                                                    value: true,
                                                },
                                                {
                                                    text: 'No',
                                                    value: false,
                                                },
                                            ]" v-model="employee.late_allowed"></v-select>
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col cols="4" class="py-1 pr-1 caption">Bonus Applicable</v-col>
                                        <v-col cols="8" class="py-0">
                                            <v-select dense outlined hide-details :items="[
                                                {
                                                    text: 'Yes',
                                                    value: true,
                                                },
                                                {
                                                    text: 'No',
                                                    value: false,
                                                },
                                            ]" v-model="employee.bonus_applicable"></v-select>
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col cols="4" class="py-1 pr-1 caption">TAX Applicable</v-col>
                                        <v-col cols="8" class="py-0">
                                            <v-select dense outlined hide-details :items="[
                                                {
                                                    text: 'Yes',
                                                    value: true,
                                                },
                                                {
                                                    text: 'No',
                                                    value: false,
                                                },
                                            ]" v-model="employee.tax_applicable"></v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="4" class="py-1 pr-1 caption">PF Applicable</v-col>
                                        <v-col cols="8" class="py-0">
                                            <v-select dense outlined hide-details :items="[
                                                {
                                                    text: 'Yes',
                                                    value: true,
                                                },
                                                {
                                                    text: 'No',
                                                    value: false,
                                                },
                                            ]" v-model="employee.provident_fund_applicable"></v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="4" class="py-1 pr-1 caption">Responsibilities</v-col>
                                        <v-col cols="8" class="py-0">
                                            <v-textarea outlined hide-details :rows="2"
                                                v-model.trim="employee.job_responsibilities"></v-textarea>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col cols="12" md="4">
                    <v-card height="100%" :elevation="1">
                        <v-toolbar color="white" :elevation="1" height="30px">
                            <v-toolbar-title class="subtitle-2">Salary Information</v-toolbar-title>
                        </v-toolbar>
                        <v-card-text class="py-0">
                            <v-row>
                                <v-col cols="12">
                                    <v-row>
                                        <v-col cols="5" class="py-1 pr-1 caption">Gross Salary</v-col>
                                        <v-col cols="7" class="py-0">
                                            <v-text-field dense outlined hide-details
                                                v-model.number="employee.gross_salary" readonly
                                                :rules="[(v) => !!v]"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="5" class="py-1 pr-1 caption">Basic</v-col>
                                        <v-col cols="7" class="py-0">
                                            <v-text-field dense outlined hide-details
                                                v-model.number="employee.basic_salary"
                                                id="basic_salary"
                                                @keyup="calculateGrossSalary($event)"
                                                :rules="[(v) => !!v]"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="5" class="py-1 pr-1 caption">Daily Salary</v-col>
                                        <v-col cols="7" class="py-0">
                                            <v-text-field dense outlined hide-details
                                                v-model.number="employee.daily_salary"
                                                id="daily_salary"
                                                @keyup="calculateGrossSalary($event)"
                                                :rules="[(v) => !!v]"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="5" class="py-1 pr-1 caption">House Rent</v-col>
                                        <v-col cols="3" class="py-0 pr-0">
                                            <v-text-field dense outlined hide-details suffix="%"
                                                v-model.number="employee.house_rent_percent" id="houseRentPercent"
                                                @keyup="calculateGrossSalary($event)"></v-text-field>
                                        </v-col>
                                        <v-col cols="4" class="py-0 pl-0">
                                            <v-text-field dense outlined hide-details
                                                v-model.number="employee.house_rent_amount"
                                                @keyup="calculateGrossSalary($event)"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="5" class="py-1 pr-1 caption">Med. Allowance</v-col>
                                        <v-col cols="3" class="py-0 pr-0">
                                            <v-text-field dense outlined hide-details suffix="%"
                                                v-model.number="employee.medical_allowance_percent"
                                                id="medicalAllowancePercent"
                                                @keyup="calculateGrossSalary($event)"></v-text-field>
                                        </v-col>
                                        <v-col cols="4" class="py-0 pl-0">
                                            <v-text-field dense outlined hide-details
                                                v-model.number="employee.medical_allowance_amount"
                                                @keyup="calculateGrossSalary($event)"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="5" class="py-1 pr-1 caption">Mob. Allowance</v-col>
                                        <v-col cols="3" class="py-0 pr-0">
                                            <v-text-field dense outlined hide-details suffix="%"
                                                v-model.number="employee.mobile_allowance_percent"
                                                id="mobileAllowancePercent"
                                                @keyup="calculateGrossSalary($event)"></v-text-field>
                                        </v-col>
                                        <v-col cols="4" class="py-0 pl-0">
                                            <v-text-field dense outlined hide-details
                                                v-model.number="employee.mobile_allowance_amount"
                                                @keyup="calculateGrossSalary($event)"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="5" class="py-1 pr-1 caption">Conveyance</v-col>
                                        <v-col cols="3" class="py-0 pr-0">
                                            <v-text-field dense outlined hide-details suffix="%"
                                                v-model.number="employee.conveyance_allowance_percent"
                                                id="conveyanceAllowancePercent"
                                                @keyup="calculateGrossSalary($event)"></v-text-field>
                                        </v-col>
                                        <v-col cols="4" class="py-0 pl-0">
                                            <v-text-field dense outlined hide-details
                                                v-model.number="employee.conveyance_allowance_amount"
                                                @keyup="calculateGrossSalary($event)"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="employee.provident_fund_applicable">
                                        <v-col cols="5" class="py-1 pr-1 caption">Provident Fund</v-col>
                                        <v-col cols="3" class="py-0 pr-0">
                                            <v-text-field dense outlined hide-details suffix="%"
                                                v-model.number="employee.provident_fund_percent"
                                                id="providentFundPercent"
                                                @keyup="calculateGrossSalary($event)"></v-text-field>
                                        </v-col>
                                        <v-col cols="4" class="py-0 pl-0">
                                            <v-text-field dense outlined hide-details
                                                v-model.number="employee.provident_fund_amount"
                                                @keyup="calculateGrossSalary($event)"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="5" class="py-1 pr-1 caption">Others</v-col>
                                        <v-col cols="3" class="py-0 pr-0">
                                            <v-text-field dense outlined hide-details suffix="%"
                                                v-model.number="employee.others_allowance_percent"
                                                id="othersAllowancePercent"
                                                @keyup="calculateGrossSalary($event)"></v-text-field>
                                        </v-col>
                                        <v-col cols="4" class="py-0 pl-0">
                                            <v-text-field dense outlined hide-details
                                                v-model.number="employee.others_allowance_amount"
                                                @keyup="calculateGrossSalary($event)"></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>

            <v-row dense>
                <v-col cols="12" md="4">
                    <v-card height="100%" :elevation="1">
                        <v-toolbar color="white" :elevation="1" height="30px">
                            <v-toolbar-title class="subtitle-2">Bank Information</v-toolbar-title>
                        </v-toolbar>
                        <v-card-text class="py-0">
                            <v-row>
                                <v-col cols="12">
                                    <v-row>
                                        <v-col cols="4" class="py-1 caption">Bank</v-col>
                                        <v-col cols="8" class="py-0">
                                            <v-combobox dense outlined hide-details item-text="name" item-value="id"
                                                @focus="$store.dispatch('bank/getBanks')"
                                                :items="$store.getters['bank/banks']"
                                                :loading="$store.getters['bank/loadingBanks']" v-model="selectedBank"
                                                append-outer-icon="mdi-plus"
                                                @click:append-outer="$router.push('/bank')"></v-combobox>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="4" class="py-1 pr-1 caption">Branch Name</v-col>
                                        <v-col cols="8" class="py-0">
                                            <v-text-field dense outlined hide-details
                                                v-model.trim="employee.bank_branch_name"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="4" class="py-1 pr-1 caption">Account Name</v-col>
                                        <v-col cols="8" class="py-0">
                                            <v-text-field dense outlined hide-details
                                                v-model.trim="employee.bank_account_name"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="4" class="py-1 pr-1 caption">Account No.</v-col>
                                        <v-col cols="8" class="py-0">
                                            <v-text-field dense outlined hide-details
                                                v-model.trim="employee.bank_account_no"></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col cols="12" md="4">
                    <v-card height="100%" :elevation="1">
                        <v-toolbar color="white" :elevation="1" height="30px">
                            <v-toolbar-title class="subtitle-2">Emergency Contact</v-toolbar-title>
                        </v-toolbar>
                        <v-card-text class="py-0">
                            <v-row>
                                <v-col cols="12">
                                    <v-row>
                                        <v-col cols="4" class="py-1 caption">Name</v-col>
                                        <v-col cols="8" class="py-0">
                                            <v-text-field dense outlined hide-details
                                                v-model.trim="employee.emergency_contact_name"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="4" class="py-1 caption">Address</v-col>
                                        <v-col cols="8" class="py-0">
                                            <v-text-field dense outlined hide-details
                                                v-model.trim="employee.emergency_contact_address"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="4" class="py-1 caption">Phone</v-col>
                                        <v-col cols="8" class="py-0">
                                            <v-text-field dense outlined hide-details
                                                v-model.trim="employee.emergency_contact_phone"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="4" class="py-1 pr-1 caption">Email</v-col>
                                        <v-col cols="8" class="py-0">
                                            <v-text-field dense outlined hide-details
                                                v-model.trim="employee.emergency_contact_email"></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col cols="12" md="4">
                    <v-card height="100%" :elevation="1">
                        <v-toolbar color="white" :elevation="1" height="30px">
                            <v-toolbar-title class="subtitle-2">Nominee Information</v-toolbar-title>
                        </v-toolbar>
                        <v-card-text class="py-0">
                            <v-row>
                                <v-col cols="12">
                                    <v-row>
                                        <v-col cols="4" class="py-1 caption">Name</v-col>
                                        <v-col cols="8" class="py-0">
                                            <v-text-field dense outlined hide-details
                                                v-model.trim="employee.nominee_name"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="4" class="py-1 caption">Address</v-col>
                                        <v-col cols="8" class="py-0">
                                            <v-text-field dense outlined hide-details
                                                v-model.trim="employee.nominee_address"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="4" class="py-1 caption">Phone</v-col>
                                        <v-col cols="8" class="py-0">
                                            <v-text-field dense outlined hide-details
                                                v-model.trim="employee.nominee_phone"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="4" class="py-1 pr-1 caption">Relation</v-col>
                                        <v-col cols="8" class="py-0">
                                            <v-text-field dense outlined hide-details
                                                v-model.trim="employee.nominee_relation"></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>

            <v-row dense>
                <v-col cols="12" md="6">
                    <v-card height="100%" :elevation="1">
                        <v-toolbar color="white" :elevation="1" height="30px">
                            <v-toolbar-title class="subtitle-2">Academic Information</v-toolbar-title>
                        </v-toolbar>
                        <v-card-text class="py-0">
                            <v-row>
                                <v-col cols="12" md="6">
                                    <v-row>
                                        <v-col cols="3" class="py-1 caption">Degree</v-col>
                                        <v-col cols="9" class="py-0">
                                            <v-select dense outlined hide-details
                                                :items="['JSC', 'SSC', 'HSC', 'BSC', 'BBA', 'BA', 'MSC', 'MBA', 'MA']"
                                                v-model="education.degree"></v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="3" class="py-1 pr-1 caption">Institute</v-col>
                                        <v-col cols="9" class="py-0">
                                            <v-text-field dense outlined hide-details
                                                v-model="education.institute_name"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="3" class="py-1 pr-1 caption">Board</v-col>
                                        <v-col cols="9" class="py-0">
                                            <v-text-field dense outlined hide-details
                                                v-model="education.board"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="3" class="py-1 pr-1 caption">Year</v-col>
                                        <v-col cols="9" class="py-0">
                                            <v-text-field dense outlined hide-details
                                                v-model="education.passing_year"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="3" class="py-1 pr-1 caption">Result</v-col>
                                        <v-col cols="9" class="py-0">
                                            <v-text-field dense outlined hide-details
                                                v-model="education.result"></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-row>
                                        <v-col cols="3" class="py-1 pr-1 caption">Achv.</v-col>
                                        <v-col cols="9" class="py-0 mb-1">
                                            <v-textarea outlined hide-details :rows="2"
                                                v-model.trim="education.achievement"></v-textarea>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="3" class="py-1 pr-1 caption">
                                            Doc
                                            <v-icon small>mdi-paperclip</v-icon>
                                        </v-col>
                                        <v-col cols="9" class="py-0">
                                            <v-file-input dense outlined hide-details v-model="selectedDocumentImage"
                                                prepend-icon=""></v-file-input>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" class="py-1 text-right">
                                            <v-btn small :elevation="1" color="primary"
                                                @click="addEducation">Add</v-btn>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>

                            <v-divider></v-divider>

                            <v-row>
                                <v-col class="pt-1">
                                    <v-simple-table dense>
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th class="text-left">Degree</th>
                                                    <th class="text-left">Institute</th>
                                                    <th class="text-left">Board</th>
                                                    <th class="text-left">Year</th>
                                                    <th class="text-left">Result</th>
                                                    <th class="text-left">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(edu, ind) in educations" :key="`edu${ind}`">
                                                    <td>{{ edu.degree }}</td>
                                                    <td>{{ edu.institute_name }}</td>
                                                    <td>{{ edu.board }}</td>
                                                    <td>{{ edu.passing_year }}</td>
                                                    <td>{{ edu.result }}</td>
                                                    <td>
                                                        <v-icon small color="red"
                                                            @click="removeEducation(ind)">mdi-delete-circle</v-icon>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col cols="12" md="6">
                    <v-card height="100%" :elevation="1">
                        <v-toolbar color="white" :elevation="1" height="30px">
                            <v-toolbar-title class="subtitle-2">Previous Employment</v-toolbar-title>
                        </v-toolbar>
                        <v-card-text class="py-0">
                            <v-row>
                                <v-col cols="12" md="6">
                                    <v-row>
                                        <v-col cols="4" class="py-1 pr-1 caption">Company</v-col>
                                        <v-col cols="8" class="py-0">
                                            <v-text-field dense outlined hide-details
                                                v-model="previousEmployment.company_name"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="4" class="py-1 pr-1 caption">Post</v-col>
                                        <v-col cols="8" class="py-0">
                                            <v-text-field dense outlined hide-details
                                                v-model="previousEmployment.post"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="4" class="py-1 pr-1 caption">Salary</v-col>
                                        <v-col cols="8" class="py-0">
                                            <v-text-field dense outlined hide-details
                                                v-model="previousEmployment.salary" type="number"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="4" class="py-1 pr-1 caption">Area</v-col>
                                        <v-col cols="8" class="py-0">
                                            <v-text-field dense outlined hide-details
                                                v-model="previousEmployment.area"></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-row>
                                        <v-col cols="4" class="py-1 pr-1 caption">Department</v-col>
                                        <v-col cols="8" class="py-0">
                                            <v-text-field dense outlined hide-details
                                                v-model="previousEmployment.department"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="4" class="py-1 pr-1 caption">Resign Cause</v-col>
                                        <v-col cols="8" class="py-0">
                                            <v-textarea outlined hide-details :rows="2"
                                                v-model="previousEmployment.resign_cause"></v-textarea>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" class="py-1 text-right">
                                            <v-btn small color="primary" @click="addEmployment">Add</v-btn>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>

                            <v-divider></v-divider>

                            <v-row>
                                <v-col cols="12" class="pt-1">
                                    <v-simple-table dense>
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th class="text-left">Company</th>
                                                    <th class="text-left">Department</th>
                                                    <th class="text-left">Post</th>
                                                    <th class="text-left">Salary</th>
                                                    <th class="text-left">Area</th>
                                                    <th class="text-left">Cause</th>
                                                    <th class="text-left">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(emp, ind) in previousEmployments" :key="`emp${ind}`">
                                                    <td>{{ emp.company_name }}</td>
                                                    <td>{{ emp.department }}</td>
                                                    <td>{{ emp.post }}</td>
                                                    <td>{{ emp.salary }}</td>
                                                    <td>{{ emp.area }}</td>
                                                    <td>{{ emp.resign_cause }}</td>
                                                    <td>
                                                        <v-icon small color="red"
                                                            @click="removeEmployment(ind)">mdi-delete-circle</v-icon>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" md="12" class="text-right">
                    <v-btn height="30px" dark color="light-blue darken-2 mr-1" :elevation="1" type="submit"
                        :loading="loading">Save</v-btn>
                    <v-btn @click="resetEmployeeForm" height="30px" dark color="deep-orange"
                        :elevation="1">Cancel</v-btn>
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>

<script>
export default {
    data: () => {
        return {
            employee: {
                id: 0,
                emp_id: "",
                name: "",
                father_name: "",
                mother_name: "",
                present_address: "",
                permanent_address: "",
                area_id: null,
                country_id: null,
                phone: "",
                email: "",
                gender: "",
                birth_date: null,
                blood_group: "",
                marital_status: "",
                spouse_name: "",
                religion: "",
                nationality: "",
                nid: "",
                details: "",

                biometric_id: "",
                department_id: null,
                shift_id: null,
                designation_id: null,
                employment_type: "",
                joining_date: null,
                reporting_boss_id: null,
                confirmation_date: null,
                ot_allowed: false,
                ot_percent: 0,
                ot_rate: 0,
                absence_allowed: false,
                late_allowed: false,
                bonus_applicable: false,
                tax_applicable: false,
                provident_fund_applicable: false,
                job_responsibilities: "",

                gross_salary: 0,
                basic_salary: 0,
                daily_salary: 0,
                house_rent_percent: 0,
                house_rent_amount: 0,
                medical_allowance_percent: 0,
                medical_allowance_amount: 0,
                mobile_allowance_percent: 0,
                mobile_allowance_amount: 0,
                conveyance_allowance_percent: 0,
                conveyance_allowance_amount: 0,
                provident_fund_percent: 0,
                provident_fund_amount: 0,
                others_allowance_percent: 0,
                others_allowance_amount: 0,

                bank_id: null,
                bank_branch_name: "",
                bank_account_name: "",
                bank_account_no: "",

                emergency_contact_name: "",
                emergency_contact_address: "",
                emergency_contact_phone: "",
                emergency_contact_email: "",

                nominee_name: "",
                nominee_address: "",
                nominee_phone: "",
                nominee_relation: "",
            },
            selectedArea: null,
            selectedCountry: null,
            selectedDepartment: null,
            selectedShift: null,
            selectedDesignation: null,
            selectedReportingBoss: null,
            selectedBank: null,
            education: {
                degree: "",
                institute_name: "",
                passing_year: "",
                result: "",
                board: "",
                achievement: "",
                document_image: null,
            },
            selectedDocumentImage: null,
            educations: [],
            previousEmployment: {
                company_name: "",
                post: "",
                salary: "",
                area: "",
                department: "",
                resign_cause: "",
            },
            previousEmployments: [],
            employeeImage: null,
            signatureImage: null,
            nomineeImage: null,


            employeeImagePreview: null,
            signatureImagePreview: null,
            nomineeImagePreview: null,

            loading: false,
            validation_errors: {},

            deleted_educations: [],
            deleted_employments: [],

            birth_date_menu: false,
            joining_date_menu: false,
            confirmation_date_menu: false,
            company: {}
        };
    },
    computed: {
        maritalStatus() {
            return this.employee.marital_status;
        },
        otApplicable() {
            return this.employee.ot_allowed;
        },
        pfApplicable() {
            return this.employee.provident_fund_applicable;
        }
    },

    watch: {
        pfApplicable(pf) {
            if (!pf) {
                this.employee.provident_fund_percent = 0;
                this.employee.provident_fund_amount = 0;
            }
        },
        otApplicable(ot) {
            if (!ot) {
                this.employee.ot_percent = 0;
            }
        },
        maritalStatus(status) {
            if (status != 'Married') this.employee.spouse_name = '';
        },
        selectedArea(area) {
            this.employee.area_id = null;
            if (area == null) return;
            this.employee.area_id = area.id;
        },
        selectedCountry(country) {
            this.employee.country_id = null;
            if (country == null) return;
            this.employee.country_id = country.id;
        },
        selectedDepartment(department) {
            this.employee.department_id = null;
            if (department == null) return;
            this.employee.department_id = department.id;
        },
        selectedShift(shift) {
            this.employee.shift_id = null;
            if (shift == null) return;
            this.employee.shift_id = shift.id;
        },
        selectedDesignation(designation) {
            this.employee.designation_id = null;
            if (designation == null) return;
            this.employee.designation_id = designation.id;
        },
        selectedReportingBoss(reporting_boss) {
            this.employee.reporting_boss_id = null;
            if (reporting_boss == null) return;
            this.employee.reporting_boss_id = reporting_boss.id;
        },
        selectedBank(bank) {
            this.employee.bank_id = null;
            if (bank == null) return;
            this.employee.bank_id = bank.id;
        },
        employeeImage(image) {
            this.employeeImagePreview = null;
            if (image != null) {
                this.employeeImagePreview = URL.createObjectURL(this.employeeImage);
            }
        },
        signatureImage(image) {
            this.signatureImagePreview = null;
            if (image != null) {
                this.signatureImagePreview = URL.createObjectURL(this.signatureImage);
            }
        },
        nomineeImage(image) {
            this.nomineeImagePreview = null;
            if (image != null) {
                this.nomineeImagePreview = URL.createObjectURL(this.nomineeImage);
            }
        },
        $route(to, from) {
            this.loading = false;
            this.resetEmployeeForm()
        }
    },

    async created() {
        if (this.$route.params.id) {
            await this.getEmployee();
        } else {
            await this.$store.dispatch("employee/getNewEmployeeId");
            this.employee.emp_id = this.$store.getters["employee/newId"];
            this.employee.biometric_id = this.employee.emp_id;
        }
        this.getCompany();
    },

    methods: {
        getCompany() {
            axios.post("/get_company")
                .then(res => {
                    this.company = res.data;
                })
        },
        async saveEmployeeInfo() {

            if (!this.$refs.employeeInfoForm.validate()) {
                this.$store.dispatch('snackbar/error', 'Fill red marked fields');
                return;
            }

            this.loading = true;

            let formData = new FormData();

            formData.append("employee", JSON.stringify(this.employee));
            formData.append("previousEmployments", JSON.stringify(this.previousEmployments));

            if (this.employeeImage)
                formData.append("image", this.employeeImage);
            if (this.signatureImage)
                formData.append("signature_image", this.signatureImage);
            if (this.nomineeImage)
                formData.append("nominee_image", this.nomineeImage);

            for (let i = 0; i < this.educations.length; i++) {
                const education = this.educations[i];
                if (education.document_image) {
                    formData.append(`document_image_${i}`, education.document_image);
                }
            }

            formData.append("educations", JSON.stringify(this.educations));

            if (this.$route.params.id) {
                formData.append("deleted_educations", JSON.stringify(this.deleted_educations));
                formData.append("deleted_employments", JSON.stringify(this.deleted_employments));
            }

            let resObj = await this.$store.dispatch("employee/saveEmployee", {
                data: formData,
                id: this.employee.id
            });

            if (resObj.hasOwnProperty("validation_errors")) {
                this.validation_errors = resObj.validation_errors;
            }
            this.loading = false;

            if (resObj.isSuccess) {
                this.resetEmployeeForm();
                if (this.$route.path !== "/employee") {
                    this.$router.push('/employee');
                }
            }
        },

        async resetEmployeeForm() {
            Object.keys(this.employee).forEach((k) => {
                if (typeof this.employee[k] == "string") {
                    this.employee[k] = "";
                } else if (typeof this.employee[k] == "number") {
                    this.employee[k] = 0;
                } else if (typeof this.employee[k] == "boolean") {
                    this.employee[k] = false;
                } else if (typeof this.employee[k] == "date") {
                    this.employee[k] = null;
                }
            });

            this.validation_errors = {};

            this.selectedArea = this.selectedCountry = this.selectedDepartment = this.selectedDesignation = this.selectedShift = null;
            this.selectedReportingBoss = this.selectedBank = this.selectedDocumentImage = null;
            this.employeeImagePreview = this.signatureImagePreview = this.nomineeImagePreview = null;
            this.employeeImage = this.signatureImage = this.nomineeImage = null;
            this.educations = [];
            this.previousEmployments = [];

            this.deleted_educations = [];
            this.deleted_employments = [];

            Object.keys(this.education).forEach(
                (key) => (this.education[key] = "")
            );

            Object.keys(this.previousEmployment).forEach(
                (key) => (this.previousEmployment[key] = "")
            );

            await this.$store.dispatch("employee/getNewEmployeeId");
            this.employee.emp_id = this.$store.getters["employee/newId"];
            this.employee.biometric_id = this.employee.emp_id;

            this.$refs.employeeInfoForm.resetValidation();
        },

        async calculateGrossSalary(e) {
            if (e.target.id == 'basic_salary') {
                this.employee.daily_salary = parseFloat(this.employee.basic_salary / this.company.salary_devided_by_for_per_day).toFixed(2);
            }
            if (e.target.id == 'daily_salary') {
                this.employee.basic_salary = parseFloat(this.employee.daily_salary * this.company.salary_devided_by_for_per_day).toFixed(2);
            }
            let basicSalary = this.employee.basic_salary;
            if (basicSalary == 0) {
                this.employee.gross_salary = 0;
                return;
            }

            if (e.target.id == "houseRentPercent") {
                this.employee.house_rent_amount = (basicSalary * this.employee.house_rent_percent) / 100;
            } else {
                this.employee.house_rent_percent = (this.employee.house_rent_amount / basicSalary) * 100;
            }

            if (e.target.id == "medicalAllowancePercent") {
                this.employee.medical_allowance_amount = (basicSalary * this.employee.medical_allowance_percent) / 100;
            } else {
                this.employee.medical_allowance_percent = (this.employee.medical_allowance_amount / basicSalary) * 100;
            }

            if (e.target.id == "mobileAllowancePercent") {
                this.employee.mobile_allowance_amount = (basicSalary * this.employee.mobile_allowance_percent) / 100;
            } else {
                this.employee.mobile_allowance_percent = (this.employee.mobile_allowance_amount / basicSalary) * 100;
            }

            if (e.target.id == "conveyanceAllowancePercent") {
                this.employee.conveyance_allowance_amount = (basicSalary * this.employee.conveyance_allowance_percent) / 100;
            } else {
                this.employee.conveyance_allowance_percent = (this.employee.conveyance_allowance_amount / basicSalary) * 100;
            }

            if (e.target.id == "providentFundPercent") {
                this.employee.provident_fund_amount = (basicSalary * this.employee.provident_fund_percent) / 100;
            } else {
                this.employee.provident_fund_percent = (this.employee.provident_fund_amount / basicSalary) * 100;
            }

            if (e.target.id == "othersAllowancePercent") {
                this.employee.others_allowance_amount = (basicSalary * this.employee.others_allowance_percent) / 100;
            } else {
                this.employee.others_allowance_percent = (this.employee.others_allowance_amount / basicSalary) * 100;
            }

            let gross_salary = +this.employee.house_rent_amount + +this.employee.medical_allowance_amount;
            gross_salary += +this.employee.mobile_allowance_amount + +this.employee.conveyance_allowance_amount;
            this.employee.gross_salary = gross_salary + +this.employee.others_allowance_amount + +basicSalary;
        },

        addEducation() {
            if (this.education.degree == '') {
                alert('Degree Required!');
                return;
            }
            if (this.education.institute_name == '') {
                alert('Institute Required!');
                return;
            }

            if (this.education.board == '') {
                alert('Board Required!');
                return;
            }

            if (this.education.passing_year == '') {
                alert('Passing Year Required!');
                return;
            }

            if (this.education.result == '') {
                alert('Result Required!');
                return;
            }


            let education = { ...this.education };

            if (this.selectedDocumentImage) {
                education.document_image = this.selectedDocumentImage;
            }

            this.educations.push(education);

            Object.keys(this.education).forEach(
                (key) => (this.education[key] = "")
            );
            this.selectedDocumentImage = null;
        },

        removeEducation(ind) {
            if (this.$route.params.id) {
                let edu_obj = this.educations[ind];
                if (edu_obj.hasOwnProperty("id")) {
                    this.deleted_educations.push(this.educations[ind].id);
                }
            }
            this.educations.splice(ind, 1);
        },

        addEmployment() {
            if (this.previousEmployment.company_name == '') {
                alert('Company Name Required');
                return;
            }
            this.previousEmployments.push({ ...this.previousEmployment });
            Object.keys(this.previousEmployment).forEach(
                (key) => (this.previousEmployment[key] = "")
            );
            this.employee.type = 'employee';
        },

        removeEmployment(ind) {
            if (this.$route.params.id) {
                let emp_obj = this.previousEmployments[ind];
                if (emp_obj.hasOwnProperty("id")) {
                    this.deleted_employments.push(this.previousEmployments[ind].id);
                }
            }
            this.previousEmployments.splice(ind, 1);
        },

        async getEmployee() {
            let employees = await this.$store.dispatch('employee/getEmployees', {
                apiParams: {
                    id: this.$route.params.id,
                    with_relations: true
                },
                options: {
                    returnData: true
                }
            });

            let employee = employees[0];

            Object.keys(this.employee).forEach(key => {
                if (typeof this.employee[key] == "boolean") {
                    this.employee[key] = employee[key] == 0 ? false : true;
                } else {
                    this.employee[key] = employee[key]
                }
            });

            this.selectedArea = {
                id: employee.area_id,
                name: employee.area_name,
            };
            this.selectedCountry = {
                id: employee.country_id,
                name: employee.country_name,
            };
            this.selectedDepartment = {
                id: employee.department_id,
                name: employee.department_name,
            };
            this.selectedShift = {
                id: employee.shift_id,
                name: employee.shift_name,
            };
            this.selectedDesignation = {
                id: employee.designation_id,
                name: employee.designation_name,
            };
            this.selectedReportingBoss = {
                id: employee.reporting_boss_id,
                name: employee.reporting_boss_name,
                display_name: employee.reporting_boss_name,
            };
            this.selectedBank = {
                id: employee.bank_id,
                name: employee.bank_name,
            };

            this.educations = employee.educations;
            this.previousEmployments = employee.previous_employments;

            this.employeeImagePreview = this.$store.state.host + employee.image;
            this.signatureImagePreview = this.$store.state.host + employee.signature;
            this.nomineeImagePreview = this.$store.state.host + employee.nominee_image;
        },
    },
};
</script>

<style scoped>
.position-relative {
    position: relative;
}

.employee-image-input {
    position: absolute;
    bottom: -15px;
    left: 45%;
}

.image-input {
    position: absolute;
    bottom: -15px;
    left: 40%;
}

>>>.v-text-field--outlined .v-input__append-outer {
    margin-top: 0 !important;
}

>>>.v-text-field--outlined .v-input__append-outer button {
    color: #fff;
    background: #3e2e6b;
}

.theme--light.v-toolbar.v-sheet {
    background-color: #5df0f096 !important;
}

.v-card__text .caption {
    color: #000;
}


.v-sheet.v-card:not(#employee_info) {
    background: #6ef6f63d !important;
}

#employee_info,
#employee_info .caption,
#employee_info .subtitle-2 {
    color: #fff !important;
}


.v-data-table thead {
    background-color: #5df0f096 !important;
}

>>>.v-avatar {
    border-radius: 6px;
}

>>>.v-btn--icon.v-size--x-large {
    height: unset;
    width: unset;
}

>>>.v-dialog>.v-card>.v-card__title {
    padding: unset;
}
</style>
